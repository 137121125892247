.ant-upload.ant-upload-drag {
  padding: 20px;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  outline: none;
  transition: border-color 0.3s;
}
.ant-upload-drag-container {
  border: none !important;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  line-height: 1.5715;
}
.ant-upload-span {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.ant-upload-list-item-name {
  margin-left: 10px;
}
.ant-upload-list-item-card-actions {
  margin-left: 20px;
  border-style: none;
  color: white;
}
.ant-btn {
  border-style: none;
  background-color: white;
}
.upload-btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
*:focus {
  outline: 0;
}
.download-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.download-container p {
  margin-top: 5px;
}
.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upload-hint {
  padding: 0px;
  margin-top: 10px;
}
.download-report-btn {
  padding: 0px !important;
  margin: 0px;
}