html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  height:100%;
}

#root {
  height: 100%;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

.ql-editor {
  min-height:200px;
}

.screenCenter {
  height: calc(100% - 56px - 40.5px);
  width: 100%;
  position: fixed !important;
}

[data-rmiz-wrap="visible"] {
  height: 100%;
}

[data-rmiz-btn-open]:hover {
  background: black;
  opacity: 0.33;
  transition: all .2s ease-in-out;
}

.highlighted {
  border-color: #bf1650 !important;
}

div[class*="FlagGroup"] {
  top:140px;
  bottom:auto;
  left:auto;
  right:420px;  
}

.addChannelBtn {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  font-size: 5em !important;
  background: white !important;
}
.subtitle {
  color: rgb(107, 119, 140);
  font-size:0.85em;
  padding-top:0.25em;
  height:1rem;
}